import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/src/components/layout.js";
import SEO from "../components/seo";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "chrome-hearts-online-privacy-policy",
      "style": {
        "position": "relative"
      }
    }}>{`CHROME HEARTS ONLINE PRIVACY POLICY`}</h1>
    <SEO title={props.pageContext.frontmatter.title} mdxType="SEO"></SEO>
    <p>{`Last Revised on July 24, 2020.`}</p>
    <p>{`This Privacy Policy for Chrome Hearts LLC ("`}<strong parentName="p">{`Company`}</strong>{`", "`}<strong parentName="p">{`we`}</strong>{`", "`}<strong parentName="p">{`us`}</strong>{`", "`}<strong parentName="p">{`our`}</strong>{`") describes how we collect, use and disclose information about users of the Company's website (`}<a parentName="p" {...{
        "href": "http://www.chromehearts.com"
      }}>{`www.chromehearts.com`}</a>{`), applications, services, tools and features (collectively, the "`}<strong parentName="p">{`Services`}</strong>{`") as well as any pay by link services operated on behalf of Chrome Hearts LLC, Chrome Hearts UK Ltd, Chrome Hearts Paris LLC, Chrome Hearts Saint Barth SAS, Chrome Hearts Taipei, Chrome Hearts Hong Kong Ltd, or Chrome Hearts Beijing Trading Co. For the purposes of this Privacy Policy, "`}<strong parentName="p">{`you`}</strong>{`" and "`}<strong parentName="p">{`your`}</strong>{`" means you as the user of the Services. We are committed to protecting and respecting your privacy. We are a limited liability company established in the United States of America with a registered office at 915 N Mansfield Avenue, Hollywood, CA 90038, United States and for the purpose of the General Data Protection Regulation (the "`}<strong parentName="p">{`GDPR`}</strong>{`"), we are the data controller.`}</p>
    <p>{`PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY USING, ACCESSING, OR DOWNLOADING ANY OF THE SERVICES, YOU AGREE TO THE USE OF INFORMATION ABOUT YOU IT DESCRIBES AND TO THE OTHER TERMS OF THIS PRIVACY POLICY. IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, PLEASE DO NOT ACCESS AND USE THE SERVICES.`}</p>
    <h2 {...{
      "id": "1-updating-this-privacy-policy",
      "style": {
        "position": "relative"
      }
    }}>{`1. UPDATING THIS PRIVACY POLICY`}</h2>
    <p>{`We may modify this Privacy Policy from time to time in which case we will update the "Last Revised" date at the top of this Privacy Policy. If we make changes, such changes will be posted on this page. Please check back frequently to see any updates or changes to this Privacy Policy. In addition, we will comply with all requirements under applicable law, which may include providing notice to you or obtaining your consent. Notice may be by email to you at the last email address you provided us, by posting notice of such changes on the Services, or by other means, consistent with applicable law. However, it is your sole responsibility to review the Privacy Policy from time to time to view any such changes. The updated Privacy Policy will be effective as of the time of posting, or such later date as may be specified in the updated Privacy Policy or as required under applicable law. `}<strong parentName="p">{`IF YOU DO NOT AGREE TO THE UPDATED PRIVACY POLICY, PLEASE DO NOT CONTINUE TO ACCESS OR USE THE SERVICES.`}</strong></p>
    <h2 {...{
      "id": "2-companys-collection-and-use-of-information",
      "style": {
        "position": "relative"
      }
    }}>{`2. COMPANY'S COLLECTION AND USE OF INFORMATION`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Information you give to us or we infer`}</em></p>
        <p parentName="li">{`In order to provide you with particular services, we may ask you to provide us with certain details or information about you and we may also infer information about you, including in the context of you being a customer in one of our stores. This information may include:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Basic contact details, including your name, e-mail address, mailing address, and phone number. We collect basic contact details to communicate with you, provide you with products and services, and to market to you.`}</li>
          <li parentName="ul">{`Account information, including username, password, other access details (e.g., security question answers), and account preferences. We collect account information to maintain and secure your account with us. If you choose to use the Services and register an account, you are responsible for keeping your account credentials safe. We highly recommend that you do not share your username, password, or other access details with anyone else. If you believe your account has been compromised, please contact us immediately.`}</li>
          <li parentName="ul">{`Payment information, including credit or debit card information and billing address. We collect payment information to process your payment and to provide you with products or services you have requested.`}</li>
          <li parentName="ul">{`Delivery account information, such as your FedEx account number, so that we can deliver to you using your delivery account.`}</li>
          <li parentName="ul">{`Any other information you choose to include in communications with us, for example, when sending us an e-mail.`}</li>
          <li parentName="ul">{`Additional information collected or inferred through your visit to our stores, gender, birthday, age, family members, relationships to other customers, friendships and significant others, fitting details, social media handles, information regarding gift recipients, pet ownership, and similar details.`}</li>
        </ul>
        <p parentName="li">{`Some features on the Services may require you to enter certain information in order to submit a form (e.g., making a purchase). You may elect not to provide this information, but doing so may prevent you from using or accessing these features.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Technical information we collect about you and your interaction with the Services`}</em></p>
        <p parentName="li">{`When you use our Services, we may also automatically collect certain information about your interaction with the Services ("`}<strong parentName="p">{`Usage Data`}</strong>{`"). To do this, we may use cookies, web beacons/clear gifs, and other geolocation tracking technologies ("`}<strong parentName="p">{`Tracking Technologies`}</strong>{`"). Usage Data may include:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Unique device identifier`}</li>
          <li parentName="ul">{`Device type and brand, such as your phone, computer, or tablet`}</li>
          <li parentName="ul">{`Mobile network information`}</li>
          <li parentName="ul">{`IP address`}</li>
          <li parentName="ul">{`Browser type`}</li>
          <li parentName="ul">{`Date and time stamps, such as the date and time you first accessed the Services`}</li>
          <li parentName="ul">{`Operating system`}</li>
          <li parentName="ul">{`Log data`}</li>
          <li parentName="ul">{`Precise location`}</li>
          <li parentName="ul">{`Other usage data and information regarding your interaction with the Services, such as clickstream data and ad impressions`}</li>
        </ul>
        <p parentName="li">{`We use the information we collect automatically to tailor features and content to you, to market to you, to provide you with offers or promotions, to run analytics and better understand user interaction with the Services, and to monitor and/or resolve fraud and security incidents.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Our Legal Bases for Processing Information About You`}</em></p>
        <p parentName="li">{`We process information about you in order to perform our contract with you, such as to:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`provide you with our products, Services, content, and functionality;`}</li>
          <li parentName="ul">{`communicate with you about your purchase or customer service inquiries;`}</li>
          <li parentName="ul">{`process and fulfill your order, including sending you any necessary e-mails related to your order such as order and shipping confirmation;`}</li>
          <li parentName="ul">{`process payments made through the Services;`}</li>
          <li parentName="ul">{`register, maintain, and secure your account or membership with us; and`}</li>
          <li parentName="ul">{`administer and provide services and customer support per your request.`}</li>
        </ul>
        <p parentName="li">{`We obtain your consent to process information about you for the following reasons:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`sign you up for our newsletters or alerts;`}</li>
          <li parentName="ul">{`verify our registered customers;`}</li>
          <li parentName="ul">{`personalize our services for you; and`}</li>
          <li parentName="ul">{`if you opted into marketing, to communicate with you about products, services, offers, promotions, events and other news and information we think will be of interest to you.`}</li>
        </ul>
        <p parentName="li">{`In order to be responsive to you and maintain our business relationship, as a matter of our legitimate interests, we will use information about you to:`}</p>
        <ul parentName="li">
          <li parentName="ul">{`to send you announcements in relation to security, privacy or administrative related communications (these communications are not marketing orientated, and we do not rely on consent, so you may not opt out);`}</li>
          <li parentName="ul">{`personalize our Services to ensure content from the Services is presented in the most effective manner for you and your device;`}</li>
          <li parentName="ul">{`administer the Services, and for internal operations, in order to conduct troubleshooting, data analysis, testing, research, statistical and survey analysis;`}</li>
          <li parentName="ul">{`maintain the safety and security of our users, Services, and business;`}</li>
          <li parentName="ul">{`build and maintain our community; and`}</li>
          <li parentName="ul">{`comply with legal obligations.`}</li>
        </ul>
        <p parentName="li">{`In addition, we may use all of the above information to comply with any applicable legal obligations, to enforce any applicable terms of service, and to protect or defend the Services, our rights, the rights of our users, or others.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "3-how-the-company-shares-information-about-you",
      "style": {
        "position": "relative"
      }
    }}>{`3. HOW THE COMPANY SHARES INFORMATION ABOUT YOU`}</h2>
    <p>{`In certain circumstances, the Company may share information about you with third parties in compliance with applicable laws. Specifically, we may share information about you:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><em parentName="strong">{`With service providers for business purposes.`}</em></strong>{` Such third parties include: (i) payment processors; (ii) data analytics vendors; (iii) security vendors; (iv) website hosting vendors; (v) email marketing providers; and (vi) professional advisors (e.g., auditors, law firms, or accounting firms). These service providers receiving this information assist us with many different functions and tasks, such as: (i) processing and fulfilling orders and returns; (ii) providing customer service and customer relationship management services; (iii) providing data storage and disaster recovery services; (iv) processing payments; (v) communicating with you; (vi) securing our website and Services and preventing fraud; (viii) running analytics and better understanding user interaction with the Services; and (ix) promoting our products and services;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><em parentName="strong">{`With our partner businesses or organizations.`}</em></strong>{` When we partner with third parties to offer a promotion or host an event, we may share information about you with such entities with whom we have partnered.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><em parentName="strong">{`For legal reasons and to protect our Services and business.`}</em></strong>{` We may share information about you in response to a legal obligation or if we have determined that it is necessary to share information about you to (i) comply with applicable law or any obligations thereunder (e.g., cooperation with law enforcement, judicial orders, and regulatory inquiries), (ii) protect our interests or those of our users, or (iii) exercise or defend legal claims.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><em parentName="strong">{`With our affiliates.`}</em></strong>{` We may share information about you with affiliates within our corporate family.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><em parentName="strong">{`In connection with an asset sale, merger, bankruptcy, or other business transaction.`}</em></strong>{` We may share information about you while negotiating or in relation to a change of corporate control such as a restructuring, merger, or sale of our assets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><em parentName="strong">{`To enforce any applicable terms of service.`}</em></strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><em parentName="strong">{`To ensure the safety and security of the Company and/or its users.`}</em></strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><em parentName="strong">{`When you request us to share certain information with third parties, such as through your use of social media widgets or login integrations.`}</em></strong></p>
      </li>
    </ul>
    <p>{`You acknowledge that such sharing of information may occur in all of the aforementioned circumstances and is permitted by and subject to this Privacy Policy.`}</p>
    <h2 {...{
      "id": "4-how-long-do-we-store-information-about-you",
      "style": {
        "position": "relative"
      }
    }}>{`4. HOW LONG DO WE STORE INFORMATION ABOUT YOU?`}</h2>
    <p>{`Alongside the commitment to offering the highest standards of service and products, we believe that our products should entitle you to personalized client treatment and incomparable after-sales services (e.g., product warranties) that may last a lifetime for some products. For this purpose, and to ensure such services, we may need to keep your name, phone number, e-mail address, mailing address, and/or other information about you for as long as it is needed for the provision of such services.`}</p>
    <p>{`When you purchase products from us, we keep information about you as long as is necessary to comply with applicable legislation regarding product guarantee and safety and in order to allow up with you regarding your experience with the product and offer you continuous advice.`}</p>
    <p>{`Cookies managed by us are stored for up to 12 months.`}</p>
    <p>{`We respect the retention periods imposed by applicable laws and regulations. To the extent your data is no longer necessary for the provision of a service (including the after sales service referred to above) or if you no longer wish to be part of our distribution list for our e-mail updates and newsletters, information about you will be deleted or archived in an anonymized or de-identified format in order to comply with applicable regulation.`}</p>
    <h2 {...{
      "id": "5-where-do-we-store-information-about-you",
      "style": {
        "position": "relative"
      }
    }}>{`5. WHERE DO WE STORE INFORMATION ABOUT YOU?`}</h2>
    <p>{`We are headquartered in the United States of America, and as such, information about you will be transferred to, and stored at/processed in the United States of America. Information about you may also be processed by staff operating outside the EEA who work for us or for one of our service providers or partners. We will take all steps reasonably necessary to ensure that information about you is treated securely and in accordance with this Privacy Policy.`}</p>
    <p>{`For transfer of data outside the EU, we will use GDPR compliant transmittal including (i) model clauses; (ii) US Privacy Shield; (iii) Binding Corporate Rules; or (iv) other EU approved methods.`}</p>
    <h2 {...{
      "id": "6-cookies-and-other-tracking-technologies",
      "style": {
        "position": "relative"
      }
    }}>{`6. COOKIES AND OTHER TRACKING TECHNOLOGIES`}</h2>
    <p><em parentName="p">{`Do Not Track Signals`}</em></p>
    <p>{`Your browser settings may also allow you to transmit a "Do Not Track" signal when you visit various websites. Like many websites, our website is not designed to respond to "Do Not Track" signals received from browsers. To learn more about "Do Not Track" signals, you can visit `}<a parentName="p" {...{
        "href": "http://www.allaboutdnt.com/"
      }}>{`www.allaboutdnt.com`}</a>{`.`}</p>
    <p><em parentName="p">{`Cookies and Other Tracking Technologies`}</em></p>
    <p>{`We use cookies to collect information about your browsing activities over time and across different websites following your use of our Services. Cookies allow us to recognize and count the number of users and to see how users move around our website when they are using it. This helps us to improve our Services and the way our website works. You can find more information about cookies and how to manage them at `}<a parentName="p" {...{
        "href": "http://www.allaboutcookies.org/"
      }}>{`www.allaboutcookies.org`}</a>{`.`}</p>
    <p>{`You may control the way in which your devices permit the use of Tracking Technologies. If you so choose, you may block or delete our cookies from your browser; however, blocking or deleting cookies may cause some of the Services, including any portal features and general functionality, to work incorrectly.`}</p>
    <p>{`Most browsers accept cookies automatically. However, you may be able to configure your browser settings to use the Services without some cookie functionality. You can delete cookies manually or set your browser to automatically delete cookies on a pre-determined schedule. For example, in the Internet Explorer menu bar, select: Tools -> Internet Options -> Browsing History -> Delete to view manual and automatic options.`}</p>
    <p>{`We may also use Flash cookies (also known as "persistent identification elements" or "local shared objects") on certain pages. Because Flash cookies cannot be controlled through your browser settings, you may click here to adjust your preferences. You can also identify Flash cookies running on your computer by visiting the Flash Player folder. Flash cookies, or LSO files, are typically stored with a ".SOL" extension. Please note that if you block cookies, some functions otherwise available on the Services may be unavailable, and we may not be able to present you with personally-tailored content.`}</p>
    <p><em parentName="p">{`Google Analytics`}</em></p>
    <p>{`We use Google Analytics, which is a web analytics tool that uses cookies to help us understand how users engage with the Services. You can opt out of Google Analytics without affecting how you visit our site – for more information on opting out of being tracked by Google Analytics across all websites you use, visit this Google page: `}<a parentName="p" {...{
        "href": "https://tools.google.com/dlpage/gaoptout"
      }}>{`here`}</a>{`.`}</p>
    <h2 {...{
      "id": "7-social-features",
      "style": {
        "position": "relative"
      }
    }}>{`7. SOCIAL FEATURES`}</h2>
    <p>{`Certain features of the Services may permit you to initiate interactions between the Services and third-party services or platforms, such as social networks ("`}<strong parentName="p">{`Social Features`}</strong>{`"). Social Features include features that allow you to click and access the Company's pages on certain third-party platforms, such as Facebook and Twitter, and from there to "like" or "share" our content on those platforms. Use of Social Features may entail a third party's collection and/or use of information about you. If you use Social Features or similar third-party services, information you post or otherwise make accessible may be publicly displayed by the third-party service you are using. Both the Company and the third party may have access to information about you and your use of both the Services and the third-party service. For more information on third-party websites and platforms, see Section 8.`}</p>
    <h2 {...{
      "id": "8-third-party-websites-and-links",
      "style": {
        "position": "relative"
      }
    }}>{`8. THIRD PARTY WEBSITES AND LINKS`}</h2>
    <p>{`Our Services may contain links to other online platforms operated by third parties. We do not control such other online platforms and are not responsible for their content, their privacy policies, or their use of information about you. Information you provide on public or semi-public venues, including information you share on third-party social networking platforms (such as Facebook or Twitter) may also be viewable by other users of the Services and/or users of those third-party online platforms without limitation as to its use by us or by a third party. Our inclusion of such links does not, by itself, imply any endorsement of the content on such platforms or of their owners or operators except as disclosed on the Services. We expressly disclaim any and all liability for the actions of third parties, including but without limitation to actions relating to the use and/or disclosure of personal information by third parties. Any information submitted by you directly to these third parties is subject to that third party's privacy policy.`}</p>
    <h2 {...{
      "id": "9-childrens-privacy",
      "style": {
        "position": "relative"
      }
    }}>{`9. CHILDREN'S PRIVACY`}</h2>
    <p>{`We do not seek or knowingly collect any information about children under 13 years of age. If we become aware that we have unknowingly collected information about a child under 13 years of age, we will make commercially reasonable efforts to delete such information from our database.`}</p>
    <p>{`If you are the parent or guardian of a child under 13 years of age who has provided us with information about them, you may contact us using the below information to request that it be deleted.`}</p>
    <h2 {...{
      "id": "10-data-security",
      "style": {
        "position": "relative"
      }
    }}>{`10. DATA SECURITY`}</h2>
    <p>{`Although we do our best to protect information about you, we cannot guarantee that any information you send to us electronically, while using the Services or otherwise interacting with us, will be secure when it is transmitted to us. We recommend that you do not use unsecure channels to communicate sensitive or confidential information to us. Please be aware though that, despite our best efforts, no security measures are perfect or impenetrable, and we cannot guarantee "perfect security." Any information you send us through any means is transmitted at your own risk.`}</p>
    <p>{`Once we receive information about you, we take appropriate technical and organizational measures, reasonable precautions and follow industry best practices to safeguard information about you against loss, theft and unauthorized use, access or modification. These safeguards help us prevent fraud and unauthorized access and maintain data accuracy. We also take reasonable precautions to limit access to information about you within our internal systems.`}</p>
    <h2 {...{
      "id": "11-california",
      "style": {
        "position": "relative"
      }
    }}>{`11. CALIFORNIA`}</h2>
    <p>{`This Section 11 applies to California residents in certain situations. Terms used in this section and not otherwise defined have the meaning given to such terms under the California Consumer Privacy Act of 2018 (CCPA).`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Disclosure of Collected Categories of Personal Information`}</em></p>
        <p parentName="li">{`Below we have listed the enumerated categories of personal information under the CCPA's definition of "personal information" and whether in the preceding twelve (12) months we have: (1) collected such categories of personal information (for more details regarding information collected, please see Section 2 above) ; and (2) disclosed such categories of personal information for a business purpose (for a list of third parties with whom we may disclose personal information, please see Section 3 above):`}</p>
        <table parentName="li">
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Category`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Examples`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Collected?`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Disclosed for Business Purpose?`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`A. Identifiers`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Name, postal address, e-mail address, IP address`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`B. Personal information categories listed in the California Customer Records statute`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Name, telephone number, credit card and debit card information`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`C. Protected classification characteristics under California or federal law`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Gender, age range`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`D. Commercial information`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Records of products purchased, obtained, or considered`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`E. Biometric information`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`F. Internet or other similar network activity`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Information on a consumer's interaction with the website`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`G. Geolocation data`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Physical location`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`H. Sensory data`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`I. Professional or employment-related information`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`J. Non-public education information collected by certain federally funded institutions`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`None`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`K. Inferences drawn from other personal information`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Profile reflecting a person's preferences, characteristics, behavior and attitudes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Yes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`No`}</td>
            </tr>
          </tbody>
        </table>
        <p parentName="li">{`A description of the specific purposes for which categories of personal information are disclosed can be found in Section 2 above.`}</p>
        <p parentName="li">{`We do not and have not in the last twelve (12) months sold personal information about you. We may share your information in the future with advertisers, but we will not "sell" your information as defined in the CCPA.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`California Privacy Rights`}</em></p>
        <p parentName="li">{`The CCPA provides California residents with the additional rights listed below in certain circumstances.`}</p>
        <p parentName="li"><em parentName="p">{`Right to Know`}</em>{`. You may have the right to know and see what certain details regarding what information you submit or we have collected about you over the past twelve months, including:`}</p>
      </li>
    </ol>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The specific pieces of personal information we have collected about you;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The categories of personal information we have collected about you;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The categories of sources from which the personal information is collected;`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The business or commercial purpose for collecting personal information about you; and`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The categories of third parties with whom we have shared personal information about you.`}</p>
        <p parentName="li"><em parentName="p">{`Right to Delete`}</em>{`. You may have the right to request deletion of the personal information we have collected from you (and direct our service providers to do the same).`}</p>
        <p parentName="li"><em parentName="p">{`Right Not to be Discriminated Against`}</em>{`. You may also have the right not to be discriminated against for exercising any of the rights listed above.`}</p>
        <p parentName="li"><em parentName="p">{`Rights Relating to Direct Marketing`}</em>{`. You may be able to request certain details about our disclosure of personal information to third parties for their own direct marketing purposes during the preceding calendar year. This request is free and may be made once a year.`}</p>
        <p parentName="li"><em parentName="p">{`Exercising Your California Privacy Rights`}</em>{`. To submit a request, please contact us using the contact information below.`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "12-european-economic-area",
      "style": {
        "position": "relative"
      }
    }}>{`12. EUROPEAN ECONOMIC AREA`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Privacy Rights`}</em></p>
        <p parentName="li">{`This Section 12 applies to you if are located in the European Economic Area ("EEA") in certain situations. If you are located in the EEA, you have certain rights in relation to the personal data (as defined by the GDPR) we hold about you. Some of these only apply in certain circumstances. We will respond to requests to exercise those rights without undue delay and at least within one month (though this may be extended by a further two months in certain circumstances). To exercise any of your rights, please contact us as set out below.`}</p>
        <ul parentName="li">
          <li parentName="ul"><strong parentName="li">{`Access`}</strong>{`: You have the right to access personal data we hold about you, how we use it, and who we share it with.`}</li>
          <li parentName="ul"><strong parentName="li">{`Portability`}</strong>{`: You have the right to receive a copy of the personal data we hold about you and to request that we transfer it to a third party, with certain exceptions.`}</li>
          <li parentName="ul"><strong parentName="li">{`Correction`}</strong>{`: You have the right to correct any of your personal data we hold that is inaccurate.`}</li>
          <li parentName="ul"><strong parentName="li">{`Erasure`}</strong>{`: In certain circumstance, you have the right to delete the personal data we hold about you.`}</li>
          <li parentName="ul"><strong parentName="li">{`Restriction of processing to storage only`}</strong>{`: You have the right to require us to stop processing the personal data we hold about you other than for storage purposes in certain circumstances.`}</li>
          <li parentName="ul"><strong parentName="li">{`Objection`}</strong>{`: You have the right to object to our processing of your personal data.`}</li>
          <li parentName="ul"><strong parentName="li">{`Objection to marketing`}</strong>{`: You can object to marketing at any time by opting out at the bottom of our emails to you.`}</li>
          <li parentName="ul"><strong parentName="li">{`Withdrawal of consent`}</strong>{`: Where we rely on consent to process your personal data, you have the right to withdraw this consent at any time by contacting us as set out below.`}</li>
        </ul>
        <p parentName="li">{`Please note that these rights are in many cases limited by law. For example, where fulfilling your request would adversely affect other individuals or our trade secrets or intellectual property, or where there are overriding public interest reasons or where we are required by law to retain your personal data.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li"><em parentName="p">{`Complaints`}</em></p>
        <p parentName="li">{`If you have complaints about how we process your personal data, please contact us at using the below information and we will respond to your request as soon as possible.`}</p>
        <p parentName="li">{`If you are a resident of the EEA and you think we have infringed data protection laws, you can file a claim with the data protection supervisory authority in the EU country in which you live or work.`}</p>
      </li>
    </ol>
    <h2 {...{
      "id": "13-contact-us",
      "style": {
        "position": "relative"
      }
    }}>{`13. CONTACT US`}</h2>
    <p>{`If you would like to exercise any of the above rights, or if you have any questions about this Privacy Policy, please contact us using any of the below methods:`}</p>
    <ul>
      <li parentName="ul">{`By e-mail at `}<a parentName="li" {...{
          "href": "mailto:privacy@chromehearts.com"
        }}>{`privacy@chromehearts.com`}</a></li>
      <li parentName="ul">{`By postal service at Chrome Hearts LLC, 915 N Mansfield Ave, Hollywood, CA 90038`}</li>
      <li parentName="ul">{`By telephone at +1 (855) 565-0542.`}</li>
      <li parentName="ul">{`Through our 'Contact Us' form at `}<a parentName="li" {...{
          "href": "https://terms.chromehearts.com/contact"
        }}>{`terms.chromehearts.com/contact`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      